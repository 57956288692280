import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import MinimalistImg from '../assets/images/testo/Minimalist-web.png';
import MedprimeImg from '../assets/images/testo/medprime-web.png';
import HaierImg from '../assets/images/testo/hair-web.png';
import SwirloImg from '../assets/images/testo/swirlyo-web.png';
import NekoImg from '../assets/images/testo/neko-web.png';
import OneStepImg from '../assets/images/testo/onestep.png';
import RoomanticImg from '../assets/images/testo/roomantic.png';
import ARMOURHeavyIMG from '../assets/images/testo/armerhavy.png';
import MsInfraconIMG from '../assets/images/testo/ms-infra.png';
import JeharospaceIMG from '../assets/images/testo/jeh-web.png';
import LeftArrowIcon from '../assets/images/left-arrow.png';
import RightArrowIcon from '../assets/images/right-arrow.png';

const testimonials = [
  {
    content: "Sky Dreamers brought our vision to life with a stunning CGI video for our new product launch. Their creativity and attention to detail were exceptional, making the launch a huge success. We couldn’t have asked for a better partner to elevate our brand visually.",
    name: "MINIMALIST TEAM", 
    designation: "",
    imgSrc: MinimalistImg
  },
  {
    content: "Sky Dreamers provided us with exceptional brand guidelines that perfectly captured our identity. Their expertise and collaborative approach have set the foundation for our upcoming website development, and we’re excited to continue working with them to bring our vision to life.",
    name: "MEDPRIME TEAM",
    designation: "",
    imgSrc: MedprimeImg
  },
  {
    content: "Sky Dreamers expertly handled our Amazon marketplace management, ensuring optimal product visibility and performance. Their strategic approach helped us maximize our presence and drive results. We are thrilled with their dedication and insights.",
    name: "HAIER TEAM",
    designation: "",
    imgSrc: HaierImg
  },
  {
    content: "Thanks to Sky Dreamers, our brand visibility increased fivefold through their exceptional social media design, CGI, and paid promotions. Their efforts also played a key role in helping us establish a new store presence in multiple regions. Their expertise made a significant impact on our growth.",
    name: "SWIRLYO TEAM",
    designation: "",
    imgSrc: SwirloImg
  },
  {
    content: "Sky Dreamers transformed our digital presence with their website design and paid promotions. In just six months, we saw our growth triple in the competitive streetwear market. Their expertise and dedication have been pivotal to our success.",
    name: "NEKO TEAM",
    designation: "",
    imgSrc: NekoImg
  },
  {
    content: "The dedication they demonstrate towards the project is highly commendable. Their team consistently goes above and beyond, ensuring every detail is meticulously attended to and executed flawlessly.",
    name: "ONE STEP PRINT TEAM",
    // designation: "VIRAJ- FOUNDER",
    imgSrc: OneStepImg
  },
  {
    content: "As a business owner, I previously lacked clarity on how to establish a truly distinct digital presence. After collaborating with this team, I gained invaluable insights revolutionizing my company's online strategy.",
    name: "ROOM ANTIQUE TEAM",
    // designation: "NILESH- FOUNDER",
    imgSrc: RoomanticImg
  },
  {
    content: "Before working with Sky Dreamers, my website struggled with a high bounce rate and low average visit duration. Their strategic approach led to remarkable improvements, greatly enhancing our online engagement.",
    name: "ARMOUR HEAVY TEAM",
    // designation: "Captain RAHUL RAI- FOUNDER",
    imgSrc: ARMOURHeavyIMG
  },
  // {
  //   content: "Sky Dreamers team of seasoned professionals transformed our website with innovative solutions. Their deep industry expertise and collaborative approach delivered tangible results, greatly benefiting our business.",
  //   name: "MS INFRACON TEAM",
  //   // designation: "Vishwajeet Sood- Partner",
  //   imgSrc: MsInfraconIMG
  // },
  {
    content: "After searching for the right agency, we found a minimalistic design approach that communicates our value. They provided comprehensive website design and development within a deadline.",
    name: "JEH AEROSPACE TEAM",
    designation: "",
    imgSrc: JeharospaceIMG
  }
];


const HomeTestimonials = () => {
  const sliderRef = useRef(null);
  const elementsRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.1,
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-slide-up');
          observer.unobserve(entry.target); // Only animate once
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    elementsRef.current.forEach(el => {
      if (el) {
        observer.observe(el);
      }
    });

    return () => {
      elementsRef.current.forEach(el => {
        if (el) {
          observer.unobserve(el);
        }
      });
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // 3 seconds per slide
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className='testimonials-sec'>
      <div className='container'>
        <div className='row mb-5'>
          <div className='col-md-8 col-12'>
            <div className={`heading-sec animate-slide-up`} ref={el => elementsRef.current.push(el)}>
              <h2>Our Customer Feedback</h2>
              <p>Don’t take our word for it. Trust our customers</p>
            </div>
          </div>

          <div className='col-md-4 col-12'>
            <div className="custom-navigation mobile-hide">
              <img src={LeftArrowIcon} alt="Previous" className="prev-btn" onClick={goToPrev} />
              <div className="nav-gap" />
              <img src={RightArrowIcon} alt="Next" className="next-btn" onClick={goToNext} />
            </div>
          </div>
        </div>
        
        {/* Custom navigation buttons */}
       
        <Slider ref={sliderRef} {...settings} style={{overflowX:'hidden'}}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className='testimonial-card' ref={el => elementsRef.current.push(el)}>
              <p className='testimonial-content'>{testimonial.content}</p>
              <div className='user-info'>
                <img src={testimonial.imgSrc} alt={`${testimonial.name}`} />
                <div>
                  <p className='name'>{testimonial.name}</p>
                  <p className='designation'>{testimonial.designation}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default HomeTestimonials;
